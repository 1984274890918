"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// declare this array as an immutable tuple const context, which allows us to get the narrow literal type directly
var assetVariants = ['gov', 'edu', 'health', 'gouvfr'];
var envVariant = process.env.ASSET_VARIANT;
var foundVariant = assetVariants.find(function (variant) { return variant === envVariant; });
// ensure that foundVariant is defined
if (!foundVariant)
    throw Error('Invalid variant name!');
// exports a typed assetVariant variable for use in most files except webpack (used before ts compiles)
var assetVariant = foundVariant;
exports.default = assetVariant;
